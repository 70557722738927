import styled from 'styled-components';
import React from 'react';
import { CSSMediaSize } from '../../const';

const CrashCourse = () => {
	return (
		<CrashCourseStyle>
			<div className="section">
				<h2>Introduction</h2>
				<p>
					The goal of this crash course is to
					explain what a femboy is, what they do
					and everything else that needs to be
					known.
				</p>
				<span>
					In the following sections we will be
					going through:
				</span>
				<ul>
					<li>How to identify a femboy</li>
					<li>
						How to communicate with a femboy
					</li>
					<li>Typical femboy vocabulary guide</li>
					<li>
						Words of warning regarding femboys
					</li>
					<li>Femboy subtypes</li>
				</ul>
			</div>
			<div className="section">
				<h2>How to identify a femboy</h2>
				<p>
					First and foremost, identifying a femboy
					might not be as hard as you think. While
					it is true that they have distinct dress
					code, they usually do not advertise it
					in any way. Hence they have that
					introverted, shy personality.
				</p>
				<p>
					Here are a few specific ways you can
					identify a femboy:
				</p>
				<table>
					<tbody>
						<tr>
							<td>Clothes</td>
							<td>
								Femboys often choose clothing
								typically associated with
								femininity, such as dresses,
								skirts, blouses, or other garments
								that may be considered
								traditionally feminine.
							</td>
						</tr>
						<tr>
							<td>Mannerisms and behavior</td>
							<td>
								They may exhibit mannerisms or
								behaviors that are perceived as
								traditionally feminine, such as
								gestures, speech patterns, or body
								language.
							</td>
						</tr>
						<tr>
							<td>Hairstyle and Grooming</td>
							<td>
								Femboys may prefer hairstyles or
								grooming practices that are
								associated with femininity, such
								as long hair, makeup, or manicured
								nails. Nowadays though, this might
								not be the case, a lot of femboys
								try to keep their general looks
								manly as usual because changes on
								hair or their skin are more
								permanent than just swapping
								clothing.
							</td>
						</tr>
						<tr>
							<td>
								Interest in feminine activities
							</td>
							<td>
								They may have interests or hobbies
								that are traditionally associated
								with femininity, such as fashion,
								makeup, or activities typically
								considered &quot;girly.&quot;
							</td>
						</tr>
						<tr>
							<td>Self-Identification</td>
							<td>
								Ultimately, the most reliable way
								to identify a femboy is through
								self-identification. If an
								individual identifies as a femboy,
								respects their identity and
								pronouns accordingly.
							</td>
						</tr>
					</tbody>
				</table>
				<p>
					Keep in mind that some of these are not
					necessarily true.
				</p>
			</div>
			<div className="section">
				<h2>How to communicate with a femboy</h2>
				<table>
					<tbody>
						<tr>
							<td>Pronoun Power</td>
							<td>
								Use the pronouns they throw your
								way. If you&apos;re lost in
								pronoun land, just ask politely.
								It&apos;s like a fun game of
								&quot;Guess the Pronoun,&quot; but
								with less pressure and more
								understanding.
							</td>
						</tr>
						<tr>
							<td>Embrace the Identity Parade</td>
							<td>
								Think of it like a fashion show
								where everyone gets to be
								themselves! No judgment, just
								fabulousness. So, strut your stuff
								and let them strut theirs.
							</td>
						</tr>
						<tr>
							<td>
								Keep Calm and Open Your Mind
							</td>
							<td>
								We&apos;re all floating in this
								crazy gender galaxy together, so
								why not embrace the diversity?
								It&apos;s like a buffet of
								identities – try a little of
								everything, and see what tickles
								your taste buds!
							</td>
						</tr>
						<tr>
							<td>
								Active Listening Level: Expert
							</td>
							<td>
								Lean in, nod your head, and give
								them the stage. It&apos;s like
								watching a riveting one-person
								show – you never know what plot
								twist or fabulous monologue might
								come next!
							</td>
						</tr>
						<tr>
							<td>Language Remix</td>
							<td>
								Keep it cool, keep it kind, and
								sprinkle in some hip slang.
								It&apos;s like a linguistic dance
								party, and everyone&apos;s invited
								to bust a move!
							</td>
						</tr>
						<tr>
							<td>Support Squad Goals</td>
							<td>
								Be their cheerleader, their hype
								person, their confetti cannon of
								encouragement. Because life&apos;s
								too short not to celebrate each
								other&apos;s fabulousness!
							</td>
						</tr>
						<tr>
							<td>Curiosity is Key</td>
							<td>
								If you&apos;re curious about their
								gender journey, ask with a
								sprinkle of sass and a dash of
								charm. It&apos;s like diving into
								a mystery novel – who knows what
								twists and turns you&apos;ll
								uncover!
							</td>
						</tr>
						<tr>
							<td>Boundaries Boulevard</td>
							<td>
								Respect their space like it&apos;s
								a VIP section at a fancy club. No
								uninvited guests allowed, just
								good vibes and mutual respect.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="section">
				<h2>Typical femboy vocabulary guide</h2>
				<table>
					<tbody>
						<tr>
							<td>Femtastic</td>
							<td>
								An exclamation of fabulousness and
								empowerment, used to describe
								something awesome or amazing.
							</td>
						</tr>
						<tr>
							<td>Slay</td>
							<td>
								To effortlessly and confidently
								excel at something, often used in
								the context of fashion or
								self-expression.
							</td>
						</tr>
						<tr>
							<td>Snatched</td>
							<td>
								Looking incredibly good or
								stylish, often used to describe
								someone&apos;s appearance or
								outfit.
							</td>
						</tr>
						<tr>
							<td>Yas Queen</td>
							<td>
								An enthusiastic expression of
								support or admiration, often used
								to cheer someone on or celebrate
								their success.
							</td>
						</tr>
						<tr>
							<td>Serving Looks</td>
							<td>
								To present oneself in a striking
								or fashionable manner, often used
								to describe someone who is owning
								their style.
							</td>
						</tr>
						<tr>
							<td>Kiki</td>
							<td>
								A gathering of friends for
								laughter, gossip, and good times,
								often centered around
								self-expression and creativity.
							</td>
						</tr>
						<tr>
							<td>Beat</td>
							<td>
								To apply makeup flawlessly, often
								used to describe a perfectly done
								face of makeup.
							</td>
						</tr>
						<tr>
							<td>Diva</td>
							<td>
								A term of endearment for someone
								who is confident, fabulous, and
								unapologetically themselves.
							</td>
						</tr>
						<tr>
							<td>Fierce</td>
							<td>
								To be bold, confident, and
								unafraid to express oneself
								authentically, often used to
								describe someone&apos;s
								personality or style.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="section">
				<h2>
					Words of warning regarding femboys
				</h2>
				<table>
					<tbody>
						<tr className="caution">
							<td>
								Caution: May Cause Fabulousness
								Overload
							</td>
							<td>
								Interacting with femboys may
								result in an influx of glamour,
								sass, and fierce fashion sense.
								Proceed with caution!
							</td>
						</tr>
						<tr className="warning">
							<td>
								Warning: High Levels of Confidence
								Detected
							</td>
							<td>
								Femboys are known for their
								unapologetic self-expression and
								confidence. Be prepared for a
								boost in self-esteem!
							</td>
						</tr>
						<tr className="alert">
							<td>Alert: Slayage Ahead</td>
							<td>
								Approach femboys with caution, as
								they are experts in the art of
								slaying. Your fashion game may
								never be the same!
							</td>
						</tr>
						<tr className="notice">
							<td>
								Notice: Potential for
								Uncontrollable Sassiness
							</td>
							<td>
								Engaging with femboys may result
								in an increase in sass levels.
								Side effects may include
								spontaneous hair flips and fierce
								comebacks!
							</td>
						</tr>
						<tr className="warning">
							<td>
								Warning: May Inspire
								Self-Expression
							</td>
							<td>
								Exposure to femboys may ignite a
								desire to express yourself
								authentically and boldly. Embrace
								your inner diva!
							</td>
						</tr>
						<tr className="caution">
							<td>
								Caution: Resistance to Glamour is
								Futile
							</td>
							<td>
								Resistance is futile when faced
								with the glamour and fabulousness
								of femboys. Prepare to be dazzled!
							</td>
						</tr>
						<tr className="alert">
							<td>
								Alert: Potential for Friendship
								Explosion
							</td>
							<td>
								Interacting with femboys may lead
								to an explosion of friendship,
								laughter, and fabulous adventures.
								Get ready for a fabulous ride!
							</td>
						</tr>
						<tr className="notice">
							<td>
								Notice: Beware of Envy Epidemic
							</td>
							<td>
								Exposure to femboys may result in
								envy from those lacking in
								fabulousness. Remember,
								there&apos;s enough fabulousness
								to go around!
							</td>
						</tr>
						<tr className="warning">
							<td>
								Warning: May Cause Sudden Fashion
								Epiphanies
							</td>
							<td>
								Engaging with femboys may lead to
								sudden bursts of fashion
								inspiration and a newfound
								appreciation for bold
								self-expression. Embrace the slay!
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="section">
				<h2>Femboy subtypes</h2>
				<table>
					<tbody>
						<tr>
							<td>
								<strong>Subtype</strong>
							</td>
							<td>
								<strong>Description</strong>
							</td>
						</tr>
						<tr>
							<td>Glitter Guru</td>
							<td>
								The Glitter Guru is all about
								sparkle, shimmer, and shine!
								They&apos;re the life of the
								party, spreading joy and glamour
								wherever they go.
							</td>
						</tr>
						<tr>
							<td>Pastel Prince/ss</td>
							<td>
								The Pastel Prince/ss is a vision
								in soft hues and delicate fabrics.
								They exude a gentle and ethereal
								charm, like a fairy tale character
								come to life.
							</td>
						</tr>
						<tr>
							<td>Gothic Glamour</td>
							<td>
								The Gothic Glamour femboy embraces
								dark aesthetics and dramatic
								flair. They&apos;re mysterious,
								bold, and unafraid to make a
								statement with their style.
							</td>
						</tr>
						<tr>
							<td>Geek Chic</td>
							<td>
								The Geek Chic femboy is a master
								of combining nerdy interests with
								fashionable flair. They&apos;re
								intelligent, witty, and
								effortlessly cool.
							</td>
						</tr>
						<tr>
							<td>Retro Rebel</td>
							<td>
								The Retro Rebel femboy channels
								vintage vibes with a modern twist.
								They&apos;re nostalgic, stylish,
								and always ahead of the curve when
								it comes to retro fashion.
							</td>
						</tr>
						<tr>
							<td>Artistic Adventurer</td>
							<td>
								The Artistic Adventurer femboy is
								a creative force to be reckoned
								with. They&apos;re experimental,
								eclectic, and unafraid to push the
								boundaries of fashion and
								self-expression.
							</td>
						</tr>
						<tr>
							<td>Bohemian Beauty</td>
							<td>
								The Bohemian Beauty femboy is
								free-spirited, earthy, and
								effortlessly chic. They&apos;re a
								breath of fresh air, embracing
								natural beauty and boho vibes.
							</td>
						</tr>
						<tr>
							<td>Urban Enigma</td>
							<td>
								The Urban Enigma femboy is edgy,
								urban, and enigmatic. They&apos;re
								a captivating blend of street
								style and high fashion, with an
								air of mystery that draws people
								in.
							</td>
						</tr>
						<tr>
							<td>Mermaid Maven</td>
							<td>
								The Mermaid Maven femboy is a
								creature of the sea, with a love
								for all things oceanic and
								iridescent. They&apos;re
								enchanting, mystical, and utterly
								mesmerizing.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="section">
				<h2>The End</h2>
				<p>
					There are still a lot of things left
					unsaid, but for now that will be enough.
					If you have any questions or
					suggestions, please don&apos;t hesitate
					to reach out!
				</p>
			</div>
		</CrashCourseStyle>
	);
};

export default CrashCourse;

const CrashCourseStyle = styled.div`
	flex-shrink: 0;
	flex-grow: 1;
	padding: 20px 50px;

	table {
		border-spacing: 0;
		padding: 20px;
		> tbody {
			> tr {
				&.caution {
					> td {
						&:first-child {
							color: #f5da42;
						}
					}
				}
				&.warning {
					> td {
						&:first-child {
							color: #ff9966;
						}
					}
				}
				&.alert {
					> td {
						&:first-child {
							color: #ff6666;
						}
					}
				}
				&.notice {
					> td {
						&:first-child {
							color: #66ccff;
						}
					}
				}
				> td {
					&:first-child {
						padding-right: 20px;
						padding-left: 20px;
						background-color: var(--c-p2);
					}
					&:not(:first-child) {
						padding-left: 20px;
					}
				}
				&:first-child {
					> td {
						&:first-child {
							padding-top: 5px;
						}
					}
				}
				&:last-child {
					> td {
						&:first-child {
							padding-bottom: 5px;
						}
					}
				}
			}
		}
	}

	${CSSMediaSize.phone} {
		padding: 20px 10px;
		table {
			padding: 10px;
			> tbody {
				> tr {
					> td {
						display: block;
						&:first-child {
							padding: 10px 5px;
							border-radius: 5px;
						}
					}
				}
			}
		}
	}
`;
