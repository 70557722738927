import styled from 'styled-components';
import React from 'react';

const P404 = () => {
	return <P404Style id="P404">404</P404Style>;
};

export default P404;

const P404Style = styled.div`
	flex-shrink: 0;
	flex-grow: 1;
`;
