import { API_URL } from './const';

export const ROUTES = {
	home: '/',
	about: '/about',
	contact: '/contact',
	donate: '/donate',
	privacyPolicy: '/privacy-policy',
	coffee: '/coffee',
	patreon: '/patreon',
	discord: '/discord',
	crashCourse: '/crash-course',
	register: '/register',
	termsOfService: '/terms-of-service',
	verify: '/verify/:token',
};

export const API_ROUTES = {
	// User
	getMe: `${API_URL}/user`,
	getUser: `${API_URL}/user/:id`,
	login: `${API_URL}/user/login`,
	register: `${API_URL}/user/register`,
	verify: `${API_URL}/user/verify/:token`,
};
