import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Verify = () => {
	const params = useParams();

	return (
		<RegisterStyle>
			verify: {params.token}
		</RegisterStyle>
	);
};

export namespace NVerify {
	//
}

export default Verify;

const RegisterStyle = styled.div`
	flex-shrink: 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;
